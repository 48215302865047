<template>
  <div class="page1">
    <div class="content">
      <!--搜索条件 -->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader" @change="parkClear"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <a-park-select
                  ref="parkSelect"
                  :instance="this"
                  parkTypeRefName="parkTypeSelect"
                  operationRefName="operationSelect"
                  areaRefName="cascader"
                ></a-park-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Date_search')">
                <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.date')" prop="showDate">
            <el-select
              v-model.trim="formInline.showDate"
              filterable
              @change="changeDateType()"
              size="15"
              style="width: 100px"
            >
              <el-option label="日" value="day">日</el-option>
              <el-option label="月" value="month">月</el-option>
              <el-option label="年" value="year">年</el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="value1"
              type="date"
              :picker-options="pickerOptions"
              placeholder="选择日期"
              v-show="formInline.showDate == 'day'"
            ></el-date-picker>
            <el-date-picker
              v-model="value2"
              type="month"
              :picker-options="pickerOptions"
              placeholder="选择月"
              v-show="formInline.showDate == 'month'"
            ></el-date-picker>
            <el-date-picker
              v-model="value3"
              type="year"
              :picker-options="pickerOptions"
              placeholder="选择年"
              v-show="formInline.showDate == 'year'"
            ></el-date-picker>
          </el-form-item> -->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="empty()" :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportFile"
                :loading="loading"
                v-if="$route.meta.authority.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          v-loading="loading"
          border
          :header-cell-style="{ background: '#EFF2F7' }"
          :data="tableData"
          style="text-align: center"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import myComponent from "@/components/autocomplete/myautoComponent";
import { exportExcelNew, dateFormat, formatDuring } from "@/common/js/public";
export default {
  name: "parkingOperationAnalysis",
  components: {
    // myComponent,
  },
  data() {
    const newDate = new Date();
    newDate.setTime(newDate.getTime() - 3600 * 1000 * 24);
    return {
      modelvalue: "",
      formInline: {
        parkId: "",
        parkName: "",
        showDate: "day",
      },
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > newDate;
        },
      },
      temParkData: {},
      selectkeys: ["date", "month", "week", "year"],
      value1: newDate,
      value2: "",
      value3: "",
      lastDay: 0,
      pageNum: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
        },
        {
          prop: "berthCount",
          label: "泊位总数",
        },
        {
          prop: "entryNum",
          label: "停车记录数(次)",
        },
        // {
        //   prop: "shouldpay",
        //   label: "订单应收金额(元)",
        //   formatter: (row, column) => {
        //     return (row.shouldpay / 100).toFixed(2) || "0.00";
        //   },
        // },
        {
          prop: "averageBerthTurnover",
          label: "日均泊位周转次数(次)",
          formatter: (row, column) => {
            if (row.averageBerthTurnover) {
              return Number(row.averageBerthTurnover).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "parkTimeRatio",
          label: "平均泊位利用率",
          formatter: (row, column) => {
            if (row.parkTimeRatio) {
              return (row.parkTimeRatio * 100).toFixed(2) + "%";
            } else {
              return "0.00%";
            }
          },
        },
        {
          prop: "averageParkTime",
          label: "平均停车时长",
          formatter: (row, column) => {
            return formatDuring(row.averageParkTime, "seconds");
          },
        },
      ],
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    querySearchAsync(queryString, cb) {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
            areaIds: areaId,
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    setParkNull() {
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.temParkData = {};
    },
    empty() {
      this.areaId = "";
      this.streetId = "";
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      this.$refs.parkSelect.clear();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    searchData() {
      // let reportType = 1;
      // if (this.formInline.showDate == "day") {
      //   this.formInline.time = dateFormat(this.value1, "yyyy-MM-dd");
      //   reportType = 1;
      // } else if (this.formInline.showDate == "month") {
      //   this.formInline.time = dateFormat(this.value2, "yyyy-MM");
      //   reportType = 2;
      // } else if (this.formInline.showDate == "year") {
      //   this.formInline.time = dateFormat(this.value3, "yyyy");
      //   reportType = 3;
      // }
      // if (this.formInline.showDate == "") {
      //   this.$alert("请选择日期！", "提示");
      //   return;
      // }
      // if (this.formInline.time == "") {
      //   this.$alert("请选择日期！", "提示");
      //   return;
      // }
      this.loading = true;
      console.log(this.$refs.parkSelect.getParkId(), "88888888888888");
      // this.$refs.parkInput.setShowVal(this.formInline.parkName);
      //   /acb/2.0/parkStatistic/parkAnalysis/
      this.$axios
        .get("/acb/2.0/parkReport/analysisPageList", {
          data: {
            // streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            // reportType: reportType,
            // reportDate: this.formInline.time,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.tableData = res.value.list;
          this.total = res.value.total * 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeDateType() {},
    selectDay(selectDay) {
      this.lastDay = selectDay;
    },
    exportFile() {
      // let reportType = 1;
      // if (this.formInline.showDate == "day") {
      //   reportType = 1;
      //   this.formInline.time = dateFormat(this.value1, "yyyy-MM-dd");
      // } else if (this.formInline.showDate == "month") {
      //   reportType = 2;
      //   this.formInline.time = dateFormat(this.value2, "yyyy-MM");
      // } else if (this.formInline.showDate == "year") {
      //   this.formInline.time = dateFormat(this.value3, "yyyy");
      //   reportType = 3;
      // }
      // if (this.formInline.showDate == "") {
      //   this.$alert("请选择日期！", "提示");
      //   return;
      // }
      // if (this.formInline.time == "") {
      //   this.$alert("请选择日期！", "提示");
      //   return;
      // }
      exportExcelNew("/acb/2.0/parkReport/analysisExport", {
        // streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        // reportType: reportType,
        // reportDate: this.formInline.time,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // reportType: reportType,
        // reportDate: this.formInline.time,
      });
    },
    handleCurrentChange(currentpage) {
      this.pageNum = currentpage;
      this.searchData();
    },
  },
  mounted() {
    // console.log("-->$route.meta.authority", this.$route.meta.authority);
    this.searchData();
  },
};
</script>

<style lang="stylus"></style>
